import Glide from '@glidejs/glide'


# Carousels on Sections 12, 14

document.addEventListener 'DOMContentLoaded', (e) ->
  glide12 = new Glide '.js-glide-12',
    type: 'carousel'
    startAt: 0
    perView: 1
    gap: 0
  .mount()

  glide14 = new Glide '.js-glide-14',
    type: 'carousel'
    startAt: 0
    perView: 1
    gap: 0
  .mount()

